import React from 'react';
import { Spinner } from 'reactstrap';

const Loader = ({ color, animation, ...rest }) => (
  <div
    className="d-flex justify-content-center align-items-center common-loader"
  >
    <Spinner color={color} className="" animation={animation} {...rest} />
  </div>
);

Loader.defaultProps = {
  color: 'warning',
  animation: 'grow',
};
export default Loader;
