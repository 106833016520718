import * as actionTypes from './TeamWiseEffortActionTypes';

const initialState = {
  teamWiseEffortFilteringData: {},
};

export const teamWiseEffortReducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.SET_TEAM_WISE_EFFORT_FILTERING_DATA:
      return {
        ...state,
        teamWiseEffortFilteringData: action.payload,
      };

    default:
      return state;
  }
};
