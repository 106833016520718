import * as actionTypes from './PaymentMilestoneActionTypes';

const initialState = {
  paymentMilestoneFilteringData: {},
};

export const paymentMilestoneReducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.SET_PAYMENT_MILESTONE_FILTERING_DATA:
      return {
        ...state,
        paymentMilestoneFilteringData: action.payload,
      };

    default:
      return state;
  }
};
