import * as actionTypes from './InvoiceActionTypes';

const initialState = {
  invoiceFilteringData: {},
};

export const invoiceReducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.SET_INVOICE_FILTERING_DATA:
      return {
        ...state,
        invoiceFilteringData: action.payload,
      };

    default:
      return state;
  }
};
