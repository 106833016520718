import React from 'react';
import { Field } from 'formik';
import { Button, InputGroup } from 'reactstrap';
import FormikInputField from 'components/Fields/FormikInputField';

const ResetPasswordForm = (props) => {
  const { handleSubmit, isSubmitting } = props;
  // const { password } = values;
  return (
    <form onSubmit={handleSubmit}>
      <InputGroup className="mt-3" size="medium">
        <Field
          id="password"
          name="password"
          type="password"
          component={FormikInputField}
          label="New Password"
          placeholder="Enter Password"
        />
      </InputGroup>

      <InputGroup size="medium">
        <Field
          id="confirmPassword"
          name="confirmPassword"
          type="password"
          component={FormikInputField}
          label="Confirm Password"
          placeholder="Confirm Password"
        />
      </InputGroup>
      {/* password strength bar will render here...... 
      The password strength bar imported from components/PasswordStrengthMeter --
       in case password strength bar needs in any other file or project it needs to be there to import */}
      {/* {password && security.showPasswordStrengthBar && (
        <PasswordStrengthMeter password={password} />
      )} */}

      <Button
        className="mt-2 btn btn-warning btn-block"
        loading={isSubmitting}
        color="warning"
        block
        type="submit"
        size="sm"
      >
        Change My Password
      </Button>
    </form>
  );
};

export default ResetPasswordForm;
