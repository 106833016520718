import * as actionTypes from './TicketsAssignedActionTypes';

const initialState = {
  ticketAssignedFilteringData: {},
};

export const ticketsAssignedReducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.SET_TICKET_ASSIGNED_FILTERING_DATA:
      return {
        ...state,
        ticketAssignedFilteringData: action.payload,
      };

    default:
      return state;
  }
};
