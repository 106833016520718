import React from 'react';
import AppContainer from 'containers/AppContainers';
import ErrorBoundary from 'components/ErrorBoundary';

const App = () => {
  return (
    <ErrorBoundary>
      <AppContainer />
    </ErrorBoundary>
  );
};

export default App;
