import Loader from 'components/Loader';
import ForgotPassword from 'pages/Login/components/ForgotPassword';
import ResetPassword from 'pages/Login/components/ForgotPassword/components/ResetPassword';
import React, { lazy } from 'react';
import { Route, Routes, Outlet } from 'react-router-dom';
// import LoginSuccess from '../pages/LoginSuccess';

const AppContainers = () => {
  const routeApp = import('../pages/MainPage');
  const routeLogin = import('../pages/Login');
  const routeLoginSuccess = import('../pages/LoginSuccess');

  const Login = lazy(() => routeLogin);
  const App = lazy(() => routeApp);
  const LoginSuccess = lazy(() => routeLoginSuccess);

  return (
    <div className="App">
      <React.Suspense fallback={<Loader />}>
        <Routes>
          <Route element={<Outlet />}>
            <Route path="/login" element={<Login />} />

            <Route exact path="/loginSuccess" element={<LoginSuccess />} />
            <Route path="*" element={<App />} />
            <Route path="/forgotPassword" element={<ForgotPassword />} />
            <Route path="/resetPassword" element={<ResetPassword />} />
          </Route>
        </Routes>
      </React.Suspense>
    </div>
  );
};

export default AppContainers;
