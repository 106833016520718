import React from 'react';
import { Field } from 'formik';
import { Button, InputGroup } from 'reactstrap';
import FormikInputField from 'components/Fields/FormikInputField';

const ForgotPasswordForm = ({ handleSubmit, isValid, dirty, isSubmitting }) => {
  return (
    <div>
      <form onSubmit={handleSubmit}>
        <div>
          <InputGroup className="mt-3" size="medium">
            <Field
              id="email"
              name="email"
              component={FormikInputField}
              label="Enter Your Email Address"
              placeholder="Email"
            />
          </InputGroup>

          <Button
            loading={isSubmitting}
            disabled={!isValid || !dirty || isSubmitting}
            color="warning"
            block
            type="submit"
            size="sm"
          >
            Submit
          </Button>
        </div>
      </form>
    </div>
  );
};

export default ForgotPasswordForm;
