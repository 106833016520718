import * as actionTypes from './ProjectsActionTypes';

const initialState = {
  projectsFilteringData: {},
};

export const projectsReducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.SET_PROJECT_FILTERING_DATA:
      return {
        ...state,
        projectsFilteringData: action.payload,
      };

    default:
      return state;
  }
};
