import * as actionTypes from './TicketsRaisedActionTypes';

const initialState = {
  ticketRaisedFilteringData: {},
};

export const ticketsRaisedReducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.SET_TICKET_RAISED_FILTERING_DATA:
      return {
        ...state,
        ticketRaisedFilteringData: action.payload,
      };

    default:
      return state;
  }
};
