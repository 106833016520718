import * as actionTypes from './ManualTimesheetActionsTypes';

const initialState = {
  manualFilteringData: {},
};

export const manualReducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.SET_MANUAL_FILTERING_DATA:
      return {
        ...state,
        manualFilteringData: action.payload,
      };

    default:
      return state;
  }
};
