import * as actionTypes from './TimesheetsActionTypes';

const initialState = {
  timesheetsFilteringData: {},
};

export const timesheetsReducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.SET_TIMESHEET_FILTERING_DATA:
      return {
        ...state,
        timesheetsFilteringData: action.payload,
      };

    default:
      return state;
  }
};
