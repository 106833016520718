import * as actionTypes from './EmployeeActionTypes';

const initialState = {
  employeeFilteringData: {},
};

export const employeeReducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.SET_EMPLOYEE_FILTERING_DATA:
      return {
        ...state,
        employeeFilteringData: action.payload,
      };

    default:
      return state;
  }
};
