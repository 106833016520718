import * as actionTypes from './ScreenshotsActionTypes';

const initialState = {
  screenshotsFilteringData: {},
};

export const screenshotsReducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.SET_SCREENSHOT_FILTERING_DATA:
      return {
        ...state,
        screenshotsFilteringData: action.payload,
      };

    default:
      return state;
  }
};
