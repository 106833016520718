import { gql } from '@apollo/client';

export const FORGOT_PASSWORD = gql`
  mutation forgotPassword($email: String!, $subDomain: String!) {
    forgotPassword(email: $email, subDomain: $subDomain)
  }
`;

export const RESET_PASSWORD = gql`
  mutation resetPassword($password: String!, $token: String!) {
    resetPassword(password: $password, token: $token)
  }
`;
