import * as actionTypes from './TimesheetsSummaryActionTypes';

const initialState = {
  timesheetsSummaryFilteringData: {},
};

export const timesheetsSummaryReducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.SET_TIMESHEET_SUMMARY_FILTERING_DATA:
      return {
        ...state,
        timesheetsSummaryFilteringData: action.payload,
      };

    default:
      return state;
  }
};
