import * as actionTypes from './AllocationActualActionTypes';

const initialState = {
  allocationActualFilteringData: {},
};

export const allocationActualReducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.SET_ALLOCATION_ACTUAL_FILTERING_DATA:
      return {
        ...state,
        allocationActualFilteringData: action.payload,
      };

    default:
      return state;
  }
};
