import {
  ApolloClient,
  createHttpLink,
  InMemoryCache,
  ApolloLink,
  split,
} from '@apollo/client';

import { GraphQLWsLink } from '@apollo/client/link/subscriptions';
import { createClient } from 'graphql-ws';
import { getMainDefinition } from '@apollo/client/utilities';
import { onError } from '@apollo/client/link/error';
import { getToken } from 'utils/UserDetail';
import { REFRESH_TOKEN } from './refreshTokenGraphql';

// const wsLink = new WebSocketLink({
//   uri: process.env.REACT_APP_WS_ENDPOINT,
//   options: {
//     reconnect: true
//   }
// });

const wsLink = new GraphQLWsLink(
  createClient({
    url: process.env.REACT_APP_WS_ENDPOINT,
  })
);

// eslint-disable-next-line import/no-mutable-exports
let client = null;

const RefreshToken = async () => {
  const gettoken = await getToken();

  const handleRefreshToken = () => {
    try {
      return client.mutate({
        mutation: REFRESH_TOKEN,
        variables: {
          token: gettoken,
        },
      });
    } catch (error) {}
  };

  return handleRefreshToken();
};

const errorLink = onError(({ graphQLErrors, networkError }) => {
  if (graphQLErrors)
    graphQLErrors.forEach(({ message, locations, path }) => {
      if (
        message &&
        message.toLocaleLowerCase().includes('auth token is expired')
      ) {
        console.log('');
      }

      if (
        localStorage.getItem('kestrel.token') &&
        message &&
        message.includes('isExpiredToken')
      ) {
        RefreshToken().then((newData) => {
          const newDatatoken = newData.data.refreshToken;
          localStorage.clear();
          window.location.reload();
          localStorage.setItem('userData', JSON.stringify(newDatatoken));
          localStorage.setItem(
            'kestrel.token',
            JSON.stringify(newDatatoken.newToken)
          );
          return newDatatoken;
        });
      }
      if (
        localStorage.getItem('kestrel.token') &&
        message &&
        message.includes('Please Set Authorization Header, isExpiredToken')
      ) {
        window.location.reload();
        localStorage.clear();
        localStorage.removeItem('isLoggedIn');
        window.location.href = '/login';
      }
      if (
        localStorage.getItem('kestrel.token') &&
        message &&
        message.includes(
          'isExpiredToken, Please Set Valid Authorization Header'
        )
      ) {
        window.location.reload();
        localStorage.clear();
        localStorage.removeItem('isLoggedIn');
        window.location.href = '/login';
      }
    });
  if (networkError) console.log(`[Network error]: ${networkError}`);
});

const httpLink = createHttpLink({
  uri: process.env.REACT_APP_GRAPHQL_ENDPOINT,
});

const cache = new InMemoryCache();

const authMiddleware = new ApolloLink((operation, forward) => {
  // add the authorization to the headers
  operation.setContext({
    headers: {
      authorization: getToken() || null,
    },
  });
  return forward(operation);
});

const link = split(
  // split based on operation type
  ({ query }) => {
    const definition = getMainDefinition(query);
    return (
      definition.kind === 'OperationDefinition' &&
      definition.operation === 'subscription'
    );
  },
  wsLink,
  ApolloLink.from([errorLink, authMiddleware, httpLink])
);

client = new ApolloClient({
  cache,
  link,
  defaultOptions: {
    watchQuery: {
      fetchPolicy: 'network-only',
      nextFetchPolicy: 'network-only',
    },
  },
});

export default client;
