import { gql } from '@apollo/client';

export const GET_ORGANIZATION_DETAILS = gql`
  query getOrganizationDetails($subDomain: String!) {
    getOrganizationDetails(subDomain: $subDomain)
  }
`;

export const GET_TEAMS = gql`
  query {
    teams {
      id
      name
    }
  }
`;

export const GET_SKILLS = gql`
  query {
    skills {
      id
      title
    }
  }
`;

export const GET_MENTORS = gql`
  query {
    getAllMentors
  }
`;

export const GET_TEAM_LEADS = gql`
  query {
    getAllTeamLeads
  }
`;

export const BUSINESS_DOMAIN = gql`
  query {
    businessDomain {
      id
      name
    }
  }
`;

export const ALL_TASK_STATUS = gql`
  query {
    taskStatus {
      id
      status
    }
  }
`;

export const APPLICATION_ROLE_PERMISSIONS = gql`
  query {
    getApplicationRolePermissionsForUser
  }
`;

export const ADMIN_PANEL_MENU_ITEMS = gql`
  query {
    getAdminPanelMenuItems
  }
`;

export const GET_APPROVAL_STATUS = gql`
  query {
    getApprovalStatus {
      id
      approvalStatus
    }
  }
`;

export const GET_BANDS = gql`
  query {
    getBands {
      id
      bandTitle
    }
  }
`;

export const GET_USER_TOKEN = gql`
  query getEmpToken($email: String!) {
    getEmpToken(email: $email)
  }
`;

export const GET_FIELD_PERMISSIONS = gql`
  query {
    getFieldPermissions
  }
`;

export const STATUS_LIST = gql`
  query {
    getTicketStatus {
      id
      ticketStatus
    }
  }
`;

export const SUPPORT_TEAM_LIST = gql`
  query {
    getSupportTeam {
      id
      name
    }
  }
`;
export const PRIORITY_LIST = gql`
  query {
    getPriorities {
      id
      priorityTitle
    }
  }
`;
