import { combineReducers } from 'redux';
import { employeeReducer } from 'pages/Employee/EmployeeReducer';
import { projectsReducer } from 'pages/Projects/ProjectsReducer';
import { invoiceReducer } from 'pages/ApproveInvoice/InvoiceReducer';
import { timesheetsReducer } from 'pages/Timesheet/TimesheetsReducer';
import { manualReducer } from 'pages/ManualTimesheet/ManualTimesheetReducer';
import { screenshotsReducer } from 'pages/Screenshots/ScreenshotsReducer';
import { timesheetDefaultersReducer } from 'pages/TimeSheetDefaulters/TimeSheetDefaultersReducer';
import { allocationAvailabilityReducer } from 'pages/AllocationAvailabilityReport/AllocationAvailabilityReducer';
import { utilizationReducer } from 'pages/TeamUtilization/TeamUtilizationReducer';
import { timesheetsSummaryReducer } from 'pages/TimesheetSummary/TimesheetsSummaryReducer';
import { allocationActualReducer } from 'pages/AllocationActualReport/AllocationActualReducer';
import { teamWiseEffortReducer } from 'pages/TeamWiseEffort/TeamWiseEffortReducer';
import { paymentMilestoneReducer } from 'pages/PaymentMilestoneDetail/PaymentMilestoneReducer';
import { ticketsAssignedReducer } from 'pages/TicketsAssigned/TicketsAssignedReducer';
import { ticketsRaisedReducer } from 'pages/TicketsRaised/TicketsRaisedReducer';

import { intrapreneurWingsReducer } from 'pages/IntrapreneurWingsReports/IntrapreneurWingsReportsReducer';

const appReducer = combineReducers({
  employeeReducer,
  projectsReducer,
  timesheetsReducer,
  manualReducer,
  screenshotsReducer,
  timesheetDefaultersReducer,
  allocationAvailabilityReducer,
  utilizationReducer,
  timesheetsSummaryReducer,
  allocationActualReducer,
  teamWiseEffortReducer,
  paymentMilestoneReducer,
  ticketsAssignedReducer,
  ticketsRaisedReducer,
  invoiceReducer,
  intrapreneurWingsReducer,
});

const rootReducer = (state, action) => {
  if (action.type === 'USER_LOGOUT') {
    return appReducer(undefined, action);
  }
  return appReducer(state, action);
};

export default rootReducer;
