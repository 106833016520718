import { useQuery } from '@apollo/client';
import ErrorBoundary from 'components/ErrorBoundary';
import Loader from 'components/Loader';
import { GET_ORGANIZATION_DETAILS } from 'contexts/AppDetailsGraphQL';
import React from 'react';
import { Helmet } from 'react-helmet';

export const AppTitleContext = React.createContext({});

const AppTitle = ({ children }) => {
  const currentUrl = window.location.href;
  const splitUrl = currentUrl.split('.');
  const splitBaseUrl = splitUrl[0].split('/');

  const { loading, error, data } = useQuery(GET_ORGANIZATION_DETAILS, {
    variables: {
      subDomain: splitBaseUrl && splitBaseUrl.length ? splitBaseUrl[2] : '',
    },
  });

  if (loading)
    return (
      <ErrorBoundary>
        <Loader />
      </ErrorBoundary>
    );
  if (error)
    return (
      <div className="text-center mt-5">
        <h6 className="f-weight-6 text-uppercase text-info">
          Something went wrong here
        </h6>
        <span>Login after some time</span>
      </div>
    );

  return (
    <ErrorBoundary>
      <AppTitleContext.Provider value={{ ...data }}>
        {children}
        <Helmet>
          <title>{data?.getOrganizationDetails?.toolName}</title>
        </Helmet>
      </AppTitleContext.Provider>
    </ErrorBoundary>
  );
};

export default AppTitle;
