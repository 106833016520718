/* eslint-disable react/no-is-mounted */
import React from 'react';

class ErrorBoundary extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      hasError: false,
      error: null,
      errorInfo: null,
    };
  }

  static getDerivedStateFromError(error) {
    // Update state so the next render will show the fallback UI
    return { hasError: true, error };
  }

  componentDidCatch(error, errorInfo) {
    // You can log the error to an error reporting service here
    this.setState({ error, errorInfo });
    // console.error('ErrorBoundary caught an error', error, errorInfo);
  }

  render() {
    const { children } = this.props;
    const { hasError, error, errorInfo } = this.state;

    if (hasError) {
      return (
        <div className="text-center">
          <h6 className="font-weight-bold text-uppercase text-info">
            Something went wrong.
          </h6>
          {error && (
            <details style={{ whiteSpace: 'pre-wrap' }}>
              <summary>Error Details</summary>
              <p>{error.toString()}</p>
              {errorInfo && <p>{errorInfo.componentStack}</p>}
            </details>
          )}
        </div>
      );
    }

    return children;
  }
}

export default ErrorBoundary;
