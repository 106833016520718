import React, { useContext, useState } from 'react';
import * as Yup from 'yup';
import { Formik } from 'formik';
import { Card, CardBody } from 'reactstrap';
import { AppTitleContext } from 'contexts/AppTitle';
import { Link, useNavigate } from 'react-router-dom';
import { useMutation } from '@apollo/client';
import { toast } from 'react-toastify';
import ForgotPasswordForm from './components/ForgotPasswordForm';
import { FORGOT_PASSWORD } from './ForgotPassGraphQL';

const validationSchema = Yup.object({
  email: Yup.string('Enter your email')
    .email('Enter a valid email')
    .required('Email is required'),
});

const ForgotPassword = () => {
  const navigate = useNavigate();
  const getOrganizationDetails = useContext(AppTitleContext);

  const {
    getOrganizationDetails: { organizationSubDomain },
  } = getOrganizationDetails;

  const initialValues = {
    email: '',
    subDomain: organizationSubDomain,
  };

  const [userEmail, setUserEmail] = useState({});
  // forgotPassword mutation
  const [forgotPassword] = useMutation(FORGOT_PASSWORD, {
    onCompleted: () => {
      navigate('/login', {
        resetPasswordUserEmail: userEmail,
      });
      toast.success(
        'Check your Email for a link to reset password. If it does not appear within few minutes, check your spam folder'
      );
    },

    onError: ({ graphQLErrors }) => {
      graphQLErrors.forEach(({ message }) => {
        toast.error(message);
      });
    },
  });

  // handle forgot password submit and call mutation to sent email
  const handleForgotPassword = async (values) => {
    try {
      setUserEmail(values);

      await forgotPassword({
        variables: values,
      });
    } catch (e) {}
  };

  return (
    <div className="no-auth-main-container">
      <div
        className="d-flex align-items-center justify-content-center"
        style={{ height: '100vh' }}
      >
        <div>
          <Card className="border-0" style={{ width: '400px' }}>
            <CardBody>
              <div>
                <h3 className="login-signup-font">Forgot Password</h3>
              </div>
              <Formik
                initialValues={initialValues}
                onSubmit={handleForgotPassword}
                validationSchema={validationSchema}
                children={(props) => (
                  <ForgotPasswordForm
                    {...props}
                    subDomain={organizationSubDomain}
                  />
                )}
              />
            </CardBody>
          </Card>
          <div className="p-3 text-center">
            Back to
            <Link className="btn btn-md btn-link p-0 ps-1" to="/login">
              Login
            </Link>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ForgotPassword;
