import { USER_TOKEN, USER_DATA } from 'constants/constants';

export const getToken = () => localStorage.getItem(USER_TOKEN);

export const setUserDetail = (data) => {
  try {
    localStorage.setItem(USER_DATA, JSON.stringify(data));
    return true;
  } catch (err) {
    return false;
  }
};

export const userDetail = () => {
  let userData = {};
  try {
    userData = JSON.parse(localStorage.getItem(USER_DATA));
  } catch (err) {
    return false;
  }
  return userData || null;
};

export const setToken = (token) => {
  try {
    localStorage.setItem(USER_TOKEN, token);
    return true;
  } catch (err) {
    return false;
  }
};

export const deleteToken = () => {
  try {
    localStorage.removeItem(USER_TOKEN);
    localStorage.removeItem(USER_DATA);
  } catch (err) {
    return false;
  }
};
