export const USER_TOKEN = 'kestrel.token';
export const USER_DATA = 'userData';
export const CLIENT_ADMIN = 'super_admin';
export const DATE_CALENDAR_FORMAT = 'dd/MM/yyyy';
export const DATE_VIEW_FORMAT = 'DD/MM/YYYY';
export const DATE_POST_FORMAT = 'YYYY-MM-DD';

export const DEFAULT_STATUS = [
  { id: 1, name: 'Active' },
  { id: 0, name: 'Inactive' },
];
export const ASSIGNED_TICKETS_TEAMS = [{ id: false, name: 'Me' }];
export const BILLING_TYPE = [
  { id: false, name: 'Hourly' },
  { id: true, name: 'Fixed Price' },
];
export const BILLABLE = [
  { id: true, name: 'Yes' },
  { id: false, name: 'No' },
];

export const YES_NO_OPTION = [
  { id: 1, name: 'Yes' },
  { id: 0, name: 'No' },
];

export const EXP_RANGE = [
  { id: '0-1', name: '0-1' },
  { id: '1-3', name: '1-3' },
  { id: '3-5', name: '3-5' },
  { id: '5-10', name: '5-10' },
  { id: '10-50', name: '10+' },
];

export const optionsName = [
  'captureTeamScreenshot',
  'teamUtilizationReportRequired',
  'isSupportTeam',
  'isTeamUsingTimesheetTool',
  'excludeTeamFromDefaulterList',
];

export const complitionOptions = [
  { id: 10, name: '10' },
  { id: 20, name: '20' },
  { id: 30, name: '30' },
  { id: 40, name: '40' },
  { id: 50, name: '50' },
  { id: 60, name: '60' },
  { id: 70, name: '70' },
  { id: 80, name: '80' },
  { id: 90, name: '90' },
  { id: 100, name: '100' },
];

export const minExpectedHrs = [
  { id: '05:00', name: '05:00' },
  { id: '05:30', name: '05:30' },
  { id: '06:00', name: '06:00' },
  { id: '06:30', name: '06:30' },
  { id: '07:00', name: '07:00' },
  { id: '07:30', name: '07:30' },
  { id: '08:00', name: '08:00' },
];

export const IN_PROGESS_STATUS = 2;

export const TIMESHEET_STATUS = [
  { id: 1, name: 'Approved' },
  { id: 2, name: 'Pending' },
];
