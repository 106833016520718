import React, { useContext } from 'react';
import { Card, CardBody } from 'reactstrap';
import * as Yup from 'yup';
import { Link, useLocation, useNavigate } from 'react-router-dom';

import { Formik } from 'formik';
import { useMutation } from '@apollo/client';
import { toast } from 'react-toastify';
import ResetPasswordForm from './components/ResetPasswordForm';
import { RESET_PASSWORD } from '../../ForgotPassGraphQL';

const validationSchema = Yup.object({
  password: Yup.string()
    .required('This field is required')
    .min(8, 'Password must be 8 characters long')
    .matches(/[0-9]/, 'Password requires a number')
    .matches(/[a-z]/, 'Password requires a lowercase letter')
    .matches(/[A-Z]/, 'Password requires an uppercase letter')
    .matches(/[^\w]/, 'Password requires a symbol'),
  confirmPassword: Yup.string()
    .oneOf([Yup.ref('password'), null], 'Passwords must match')
    .required('Password confirm is required'),
});

const initialValues = {
  password: '',
  confirmPassword: '',
};

const ResetPassword = () => {
  const { search } = useLocation();
  const navigate = useNavigate();

  const urlStr = search.replaceAll('+', '_plus_');
  let token = new URLSearchParams(urlStr).get('token');
  token = token ? token.replaceAll('_plus_', '+') : '';

  // resetPassword mutation
  const [resetPassword] = useMutation(RESET_PASSWORD, {
    onCompleted: () => {
      toast.success('Your password was sucessfully updated.');
      navigate('/login');
    },
    onError: ({ graphQLErrors }) => {
      graphQLErrors.forEach(({ message, extensions: { code } }) => {
        if (code === 'INTERNAL_SERVER_ERROR') toast.error(message);
      });
    },
  });

  const handleResetPassword = async (values) => {
    const { password } = values;
    const data = {
      password,
      token,
    };
    await resetPassword({
      variables: data,
    });
  };
  return (
    <div className="no-auth-main-container">
      <div
        className="d-flex align-items-center justify-content-center"
        style={{ height: '100vh' }}
      >
        <div>
          <Card className="border-0" style={{ width: '400px' }}>
            <CardBody>
              <div className="text-center">
                {/* <img src={Logo} alt="Logo" /> */}

                <h3>Change Password</h3>
              </div>
              <Formik
                initialValues={initialValues}
                onSubmit={handleResetPassword}
                validationSchema={validationSchema}
                children={(props) => <ResetPasswordForm {...props} />}
              />
            </CardBody>
          </Card>
          <div className="p-3 text-center">
            Back to
            <Link className="btn btn-md btn-link p-0 ps-1" to="/login">
              Login
            </Link>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ResetPassword;
