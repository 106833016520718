import React from 'react';
import { FormGroup, Input, Label } from 'reactstrap';
import { ErrorMessage } from 'formik';
import ErrorBoundary from 'components/ErrorBoundary';

const FormikInputField = ({
  field: { name, ...fields },
  form: { touched, errors, handleBlur, setFieldValue },
  label,
  id,
  classes,
  required,
  ...rest
}) => {
  const handleChange = (e) => {
    const { value } = e.target;
    setFieldValue(name, value, true);
  };

  return (
    <ErrorBoundary>
      <FormGroup className="flex-grow-1">
        {label && (
          <Label htmlFor={id} className="label-color">
            {label}
            {required && <span className="text-danger"> *</span>}
          </Label>
        )}
        <Input
          {...fields}
          onChange={handleChange}
          bsSize="md"
          className={classes}
          invalid={Boolean(touched[name] && errors[name])}
          onBlur={handleBlur}
          {...rest}
        />

        <ErrorMessage
          name={name}
          render={(msg) => {
            return <div className="text-danger f-12">{msg}</div>;
          }}
        />
      </FormGroup>
    </ErrorBoundary>
  );
};

export default FormikInputField;
