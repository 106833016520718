import * as actionTypes from './IntrapreneurWingsReportsActionTypes';

const initialState = {
  intrapreneurWingsFilteringData: {},
};

export const intrapreneurWingsReducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.SET_INTRAPRENEUR_WINGS_FILTERING_DATA:
      return {
        ...state,
        intrapreneurWingsFilteringData: action.payload,
      };

    default:
      return state;
  }
};
